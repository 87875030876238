import axios from 'axios'

const ipGeolocation = axios.create({
  baseURL: `https://api.ipgeolocation.io/`,
  timeout: 180000 /* 3 minutes */,
  headers: {}

})
export const geolocation = async () => {
  try {
    return new Promise((resolve, reject) => {
      ipGeolocation.get(`ipgeo?apiKey=${process.env.VUE_APP_IP_GEOLOCATION_KEY}`)
        .then((data) => {
          return resolve(data)
        })
        .catch((error) => {
          return resolve(error.response)
        })
    })
  } catch (error) {
  }
}
