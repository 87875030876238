<template>
  <b-card id="login-card">
      <div class="text-center">
        <img
          src="../img/new-logo.svg"
          style="margin: 2.5rem 0"
          height="88px"
          alt=""
        />
      </div>

    <div  v-if='conponent === conponentLogin' style="margin-bottom: 3rem; display: flex; justify-content: center">
      <b-form style="width: 95%">
        <h2 style="font-weight: bolder; color: #4c4d4a">Login</h2>
        <b-input-group>
          <b-form-input
            id="login"
            v-model="username"
            v-on:keyup.enter="login"
            type="email"
            required
            placeholder="E-mail*"
          ></b-form-input>
          <b-input-group-append
            style="
              display: flex;
              align-items: center;
              position: absolute;
              right: 10px;
              top: 27px;
              z-index: 5;
            "
          >
            <b-icon
              font-scale="1.3"
              color="#707070
"
              icon="person"
            ></b-icon>
          </b-input-group-append>
        </b-input-group>

        <b-input-group>
          <b-form-input
            id="password"
            :type="showTypedPassword ? 'text' : 'password'"
            v-on:keyup.enter="login"
            placeholder="Senha*"
            v-model="password"
            required
          >
          </b-form-input>

          <b-input-group-append
            style="
              display: flex;
              align-items: center;
              position: absolute;
              right: 10px;
              top: 28px;
              z-index: 5;
            "
          >
            <b-icon
              v-if="showTypedPassword"
              font-scale="1.3"
              color="#707070"
              icon="eye-slash"
              @click="showTypedPassword = !showTypedPassword"
            ></b-icon>
            <b-icon
              v-else
              font-scale="1.3"
              color="#707070"
              icon="eye"
              @click="showTypedPassword = !showTypedPassword"
            ></b-icon>
          </b-input-group-append>
        </b-input-group>
        <b-alert show variant="success" :class="{ hidden: !hasMessage() }">
          {{ message }}
        </b-alert>

        <b-alert show variant="danger" :class="{ hidden: !hasErrors() }">
          {{ error }}<br />
          <b-button v-if="confirmationEmail" @click="resendEmail">
            Reenviar e-mail
          </b-button>
        </b-alert>

        <div
          style="display: flex;
                 align-items: center;
                 justify-content: flex-end;
                 font-weight: 400;
                 font-size: 14px;
                 gap: 5px;"
        >
            <span style="color: #808080 !important; text-decoration: none !important;"
            >
              Esqueceu sua senha?
            </span>
          <a
            href="#"
            class='mb-1'
            style="text-decoration: underline #04a8f3 !important"
            @click="change('/esqueci-senha')"
          >
            <strong>
              Clique aqui
            </strong>
          </a>
        </div>

        <b-input-group>
          <b-overlay
            :show="processing"
            rounded
            opacity="0.6"
            spinner-small
            spinner-variant="primary"
            class="d-inline-block w-100"
          >
            <b-input-group>
              <b-button
                :disabled="processing"
                class="btn-login"
                @click="login"
              >Login
              </b-button>
            </b-input-group>
          </b-overlay>

          <b-button
            @click="change('/cadastrar')"
            block
            class="mt-2 mt-sm-2 btn-register"
          >
            Cadastre-se
          </b-button>
        </b-input-group>
      </b-form>
    </div>

    <div  v-if='conponent === conponentConfirmCode' style="margin-bottom: 3rem;">
      <h2 style="font-weight: bolder; color: #4c4d4a">Código de segurança</h2>
      <confirm-code
        :username='confirmationEmail'
        :phone='phone'
        functionality='Login'
        layoutBtn="12"
        :message='messageConfirmCode'
        :requestChangeOfData='requestChangeOfData'
        @informSecurityCodeIsValid='informSecurityCodeIsValid()'
        @changePhone='hideModal'
      />
    </div>

    <div  v-if='conponent === conponentChangePhone' style="margin-bottom: 3rem;">
      <h2 style="font-weight: bolder; color: #4c4d4a">Alterar telefone</h2>
      <change-phone
        :username='confirmationEmail'
        :phone='phone'
        @new-phone='setNewPhone'
      />
    </div>
  </b-card>
</template>

<script>
import {geolocation} from '../../services/ipGeolocation'
import ConfirmCode from '@/views/Access/ConfirmCode'
import ChangePhone from '@/views/Access/ChangePhone'

import { mapActions } from 'vuex'
export default {
  namer: 'Login',
  components:{
    ConfirmCode,
    ChangePhone,
  },
  async mounted() {
    document.getElementById('password').addEventListener('keyup', (e) => {
      this.password = this.password.trim()
    })
  },
  computed: {
    message() {
      return this.$store.getters['access/message']
    },
    error() {
      return this.$store.getters['access/error']
    },
    user() {
      return this.$store.getters['user/current']
    },
  },
  created() {
    this.redirect = this.$route.query
    this.$store.dispatch('access/updateError', undefined)
  },
  data() {
    return {
      phone: '',
      conponent: 1,
      conponentLogin: 1,
      conponentConfirmCode: 2,
      conponentChangePhone: 3,
      unconfirmedEmail: false,
      validatedPhone: false,
      messageConfirmCode: '',
      confirmationEmail: '',
      errorResponse: '',
      password: '',
      processing: false,
      requestChangeOfData: false,
      verifiedsecurityCodeValidated: false,
      username: '',
      redirect: undefined,
      confirmSecurityCode: false,
      showTypedPassword: false,
    }
  },
  methods: {
    ...mapActions('userActionLogs', {
      createUserActionLogsLocation: 'createUserActionLogsLocation',
    }),
    ...mapActions('user', {
      setCheckPendingInvoice: 'setCheckPendingInvoice',
      setShowModalRenew: 'setShowModalRenew',
    }),
    ...mapActions('auth', {
      handleSaveLocalStorageSessionId: 'handleSaveLocalStorageSessionId',
      handleSaveLocalStorageToken: 'handleSaveLocalStorageToken',
    }),

    ...mapActions('updateNews', {
      handleProcessUserWhoSawTheUpdates: 'handleProcessUserWhoSawTheUpdates',
    }),
    setNewPhone(newPhone){
      this.phone = newPhone;
      this.messageConfirmCode = `Por uma questão de segurança, por gentileza, verifique suas mensagens do número de celular
                                     <strong>(${ this.phone.substring(0, 2) }) xxxxx-xx${ this.phone.substring(this.phone.length - 2) }
                                     </strong> para nos informar a seguir qual o código de segurança. <br/>O Código foi enviado por SMS e WhatsApp.`
      this.conponent = this.conponentConfirmCode;
    },

    async hideModal() {
      await this.$store.dispatch('access/updateError', this.errorResponse)
      this.conponent = this.conponentChangePhone
    },

    change(to) {
      this.$store.dispatch('access/updateError', '')
      this.$router.push({ path: to })
    },

    hasErrors() {
      return !!this.error
    },

    hasMessage() {
      return !!this.message
    },

    async login() {
      await this.$store.dispatch('access/updateMessage', '')
      this.processing = true
      if (!this.username || !this.password) {
        await this.$store.dispatch('access/updateError', 'Dados obrigatórios')
        return
      }
      try {
        const location = await this.getLocation()
        this.$api.post('auth/signin', {
            username: this.username,
            password: this.password,
            location,
            verifiedsecurityCodeValidated: this.verifiedsecurityCodeValidated,
          }).then(async (res) => {
            this.verifiedsecurityCodeValidated = false;
            const { data } = res
            const { sessionId, tokenHofacil } = data
            if (res.data.error) {
              this.confirmationEmail = res.data.useremail
              this.phone = res.data.phone
              this.errorResponse = res.data.error
              this.validatedPhone = res.data.validatedPhone
              this.requestChangeOfData = res.data.requestChangeOfData
              this.confirmSecurityCode = res.data.confirmSecurityCode
              if(this.confirmSecurityCode){
                this.messageConfirmCode = this.requestChangeOfData ?
                  `Por uma questão de segurança, por gentileza, verifique suas mensagens do número de celular
                   <strong>(${ this.phone.substring(0, 2) })
                    xxxxx-xx${ this.phone.substring(this.phone.length - 2) } </strong>  para nos informar a seguir qual o código de segurança. <br/> O Código foi enviado por SMS e WhatsApp.`
                    :
                  `Por uma questão de segurança, por gentileza, verifique suas mensagens do número de celular
                   <strong>(${ this.phone.substring(0, 2) }) xxxxx-xx${ this.phone.substring(this.phone.length - 2) }
                   </strong> para nos informar a seguir qual o código de segurança. <br/>O Código foi enviado por SMS e WhatsApp.`
                this.conponent = this.conponentConfirmCode
              }
              await this.$store.dispatch('access/updateError', res.data.error)
            } else if (this.redirect && this.redirect.plano) {
              await this.$store.dispatch('access/updateMessage', '')
              await this.handleSaveLocalStorageSessionId(sessionId)
              await this.handleSaveLocalStorageToken(tokenHofacil)
              const user = (await this.$api.get(`users/${sessionId}`)).data;
              await this.$store.dispatch('user/updateUser', user);

              return this.$router.push({
                name: 'Checkout',
                query: this.redirect,
              })
            } else {
              await this.handleSaveLocalStorageSessionId(sessionId)
              await this.handleSaveLocalStorageToken(tokenHofacil)
              const user = (await this.$api.get(`users/${sessionId}`)).data;
              await this.$store.dispatch('user/updateUser', user);
              await this.$store.dispatch('access/updateMessage', '')
              await this.setCheckPendingInvoice(true)
              await this.setShowModalRenew(true)
              await this.handleProcessUserWhoSawTheUpdates();
              return this.$router.push({ name: 'LegalSearch' })
            }
          }).catch((err) => {
            if (err.response.status === 401)
              this.$store.dispatch(
                'access/updateError',
                'Dados de acesso inválidos'
              )
            else
              this.$store.dispatch(
                'access/updateError',
                err.response.statusText
              )
          }).finally(() =>{
            this.processing = false
          })
      } catch (error) {
        console.log(error)
      }

    },

    resendEmail() {
      this.$api.post('users/resendEmail', {
          username: this.confirmationEmail,
        })
        .then((res) => {
          if (res.data.message)
            this.$store.dispatch('access/updateMessage', res.data.message)
          if (res.data.error)
            this.$store.dispatch('updateError', res.data.error)
        })
        .catch((err) => {
          this.$store.dispatch('updateError', err)
        })
        this.confirmationEmail = ''
    },

    async getLocation(){
      try {
        let location = {}
        const { data, status } = await geolocation()
        if(status === 200) {
          location = data
        }
        return location
      } catch (e) {

      }
    },

    async informSecurityCodeIsValid() {
      this.conponent = this.conponentLogin
      this.verifiedsecurityCodeValidated = true
      await this.$store.dispatch('access/updateMessage', 'Código de segurança verificado com sucesso. Clique em Acessar para fazer login!')
      await this.login()
    }
  },
}
</script>

<style>
.btn-register{
  font-style: normal;
  font-weight: 700;
  border-radius: 5px!important;
  background-color: transparent;
  border: 2px solid #04a8f3;
  color: #04a8f3;
  font-size: 18px;
}
.btn-register:hover {
  background-color: #04a8f3 !important;
  color: #fff !important;
  border: 2px solid #04a8f3;
}

.btn-login {
  width: 100%;
  height: 40px;
  background: #04A8F3;
  border-radius: 5px!important;
  border: 1px solid #04A8F3;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #FFFFFF;
}
.btn-login:hover {
  background: #022245;
}
</style>
