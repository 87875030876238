<template>
  <section class="w-100 register">
    <section class=" pb-10">
      <b-alert show variant="success" :class="{ hidden: !hasMessage() }">
        {{ messageSuccess }}
      </b-alert>
      <b-alert show variant="danger" :class="{ hidden: !hasErrors() }">
        {{ error }}<br />
      </b-alert>
      <p v-html="message"></p>

      <section class="mt-1 w-100">
        <label class='label_input_phone'>Informe o código recebido </label>
        <b-form-input
          id="securityCode"
          class="input_code"
          :state="validCode"
          v-model="securityCode"
          v-mask="['##########']"
          placeholder="Digite aqui o código de segurança enviado via SMS."
          aria-describedby="input-live-help input-live-feedback"
          trim
          @keydown.enter.prevent='validateSecurityCode()'
        >
        </b-form-input>
        <b-form-invalid-feedback
          :state="validCode"
          class="w-75 position-absolute"
          id="securityCode"
        >
          Código de segurança inválido
        </b-form-invalid-feedback>
      </section>

      <section class='mt-4'>
        <label >
          O código pode levar alguns instantes para chegar.
        </label>
      </section>

      <b-row class="mt-1">
        <b-col :xl="layoutBtn" class="mb-3">
          <b-overlay
            :show="loading"
            rounded
            opacity="0.6"
            spinner-small
            spinner-variant="primary"
            class="w-100 d-flex align-items-center"
          >
            <base-button
              block
              :disabled="!validate()"
              @click="validateSecurityCode()"
              class="btn"
            >
              <span class="text_btn">Validar código</span>
            </base-button>


          </b-overlay>
        </b-col>

        <b-col :xl="layoutBtn" class="mb-2">
          <b-overlay
            :show="loadingUpdatePhone"
            rounded
            opacity="0.6"
            spinner-small
            spinner-variant="primary"
            class="w-100"
          >
            <base-button
              @click="requestToChangeData()"
              style="height: 45px"
              class="w-100 mr-2"
            >
              <span class="text_btn">Alterar telefone</span>
            </base-button>
          </b-overlay>
        </b-col>

        <b-col class="mb-2 resend">
          <section class='resend-btn'>
            <b-overlay
              :show="resendSMSLoading"
              rounded
              opacity="0.6"
              spinner-small
              spinner-variant="primary"
              class="w-100 d-flex align-items-center"
            >
              <base-button
                v-if="nextService === 'TWILIO'"
                block
                :disabled="disableSendSMSAgain"
                @click="resendSMS()"
                class="btn"
              >
                <span class="text_btn">Não recebeu o código? Clique aqui para reenviar.</span>
              </base-button>
            </b-overlay>
          </section>

          <section class='d-flex align-items-center resend-timer'>
            <div class='rotate'>
              <svg height="60" width="60">
                <circle cx="30" cy="30" r="20" stroke="var(--green)" stroke-width="5" stroke-dasharray='155' :stroke-dashoffset='strokeDashoffset' fill="#FFFFFF" />
              </svg>
            </div>

            <h3>{{time}}</h3>
          </section>
        </b-col>
      </b-row>
    </section>
  </section>
</template>

<script>
import { mask } from 'vue-the-mask'
import { sendSMS, validateSecurityCode } from '../../services/SMS'
import { mapActions } from 'vuex'
import moment from 'moment'
export default {
  namer: 'ConfirmCode',
  directives: { mask },
  components: {},
  props: {
    username: { type: String, default: '' },
    phone: { type: String, default: '' },
    message: { type: String, default: '' },
    requestChangeOfData: { type: Boolean, default: false },
    layoutBtn: { type: String, default: '6' },
    functionality: { type: String, default: '' },
  },
  computed: {
    userProfile() {
      return this.$store.getters['user/userProfile']
    },
    validCode() {
      return this.securityCodeIsValid
    },
    messageSuccess() {
      return this.$store.getters['access/message']
    },
    error() {
      return this.$store.getters['access/error']
    },
    time(){
      return this.start ? moment(this.start).format('mm:ss') : ''
    }
  },
  data() {
    return {
      service: '',
      nextService: '',
      securityCode: '',
      securityCodeIsValid: null,
      loading: false,
      loadingUpdatePhone: false,
      sentTwilio: false,
      interval: null,
      strokeDashoffset: 30,
      strokeDashoffsetIncrement: 1.04166666667,
      strokeDashoffsetMax: 155,
      start: null,
      end: null,
      disableSendSMSAgain: true,
      resendSMSLoading: false,
    }
  },
  validations() {
    return {}
  },
  created() {
    this.$store.dispatch('access/updateError', '')
    this.$store.dispatch('access/updateMessage', '')
  },
  async mounted() {
    this.service = 'AWS';
    this.nextService = 'TWILIO';
    await this.handleSendSMS()
    this.init()
  },
  methods: {
    ...mapActions('user', {
      handleRequestToChangeData: 'handleRequestToChangeData',
    }),
    init(){
      this.start = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), new Date().getHours(), 2, 0);
      this.end = moment(this.start).add(-2, 'minutes').toDate();
      this.disableSendSMSAgain = true
      this.strokeDashoffset = 30
      this.startTimer()
    },

    startTimer() {
      this.interval = setInterval( () => {
        if(moment(this.start).isAfter(moment(this.end))){
          this.start = moment(this.start).add(-1, 'seconds').toDate()
        } else {
          this.disableSendSMSAgain = false
        }
        if(this.strokeDashoffset < this.strokeDashoffsetMax) {
          this.strokeDashoffset += this.strokeDashoffsetIncrement
        }
      }, 1000);
    },

    beforeDestroy() {
      clearInterval(this.interval);
    },

    hasMessage() {
      return !!this.messageSuccess
    },

    hasErrors() {
      return !!this.error
    },

    async requestToChangeData() {
      await this.$store.dispatch('access/updateMessage', '')
      await this.$store.dispatch('access/updateError', '')
      try {
        this.loadingUpdatePhone = true
        const { status, data } = await this.handleRequestToChangeData({
          username: this.username,
        })
        this.loadingUpdatePhone = false
        let message = data.error
        if (status === 200) {
          message = data.message
          await this.$store.dispatch('access/updateMessage', message)
          return
        }
        await this.$store.dispatch('access/updateError', message)
      } catch (e) {
        this.loadingUpdatePhone = false
      }
    },

    validate() {
      return this.securityCode
    },

    async resendSMS() {
      clearInterval(this.interval);
      this.resendSMSLoading = true
      this.service = 'TWILIO'
      this.nextService = 'TWILIO'
      await this.handleSendSMS()
      this.resendSMSLoading = false
      this.init()
    },
    async resendWhatsApp() {
      clearInterval(this.interval);
      this.resendSMSLoading = true
      this.service = 'Z-API'
      this.nextService = 'TWILIO'
      await this.handleSendSMS()
      this.resendSMSLoading = false
      this.init()
    },

    async handleSendSMS() {
      try {
        await this.$store.dispatch('access/updateError', '')
        await this.$store.dispatch('access/updateMessage', '')
        const body = {
          username: this.username,
          phone: this.phone.replace(/\D/g, ''),
          functionality: this.functionality,
          service: this.service,
        }
        const { status } = await sendSMS(body)
        if (status !== 200) {
          await this.$store.dispatch(
            `access/updateError`,
            `Não foi possível enviar sms, verifique o número do telefone. Caso o número do telefone esteja errado clique em "Alterar telefone".`
          )
        }
      } catch (e) {
        await this.$store.dispatch(
          'access/updateError',
          `Não foi possível enviar sms, verifique o número do telefone. Caso o número do telefone esteja errado clique em "Alterar telefone".`
        )
      }
    },

    async validateSecurityCode() {
      try {
        await this.$store.dispatch('access/updateMessage', null)
        this.loading = true
        this.securityCodeIsValid = null
        const body = {
          username: this.username,
          securityCode: Number(this.securityCode),
        }
        const { data } = await validateSecurityCode(body)
        this.securityCodeIsValid = !!data.securityCode
        if (this.securityCodeIsValid) {
          this.$emit('informSecurityCodeIsValid')
        }
        this.loading = false
      } catch (e) {}
    },
  },
}
</script>

<style scoped>
.input_code {
  width: 100%!important;
  height: 45px!important;
  border-radius: 7px!important;
  margin-right: 10px!important;
  margin-top: -5px!important;
  margin-bottom: 1px!important;
  padding-left: 10px !important;
  padding-bottom: 20px !important;
  border: 1.5px solid #cccccc !important;
}

.text_btn {
  font-weight: 600;
}

.btn {
  min-height: 45px;
  border-radius: 5px;
  border: none;
}

.btn:hover {
  color: white;
}

.btn_quantity_person {
  background: transparent;
  border-radius: 20px;
  color: #48626f;
  border: 2px solid #c1d5e4;
}

.btn_quantity_person:hover {
  border-color: var(--blue);
}
.label_input_phone{
  font-weight: bold;
  font-size: 17px
}
.rotate{
  transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
}
.resend{
  display: flex;
  align-items: center;
  .resend-btn{
    width: 75%  ;
  }
  .resend-timer{

    width: 25%  ;
  }
}
@media only screen and (max-width: 1200px) {
  .resend{
    display: block;
    .resend-btn{
      width: 100%  ;
    }
    .resend-timer{
      margin-top: 10px;
      margin-right: 40px;
      float: right;
      width: 25%  ;
    }
  }
}
</style>
